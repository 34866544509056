import { TIMER_START, TIMER_TICK } from './types';

export const startTimer = () => (dispatch, getState) => {

  if (getState.time) {

    clearInterval(getState.time.interval);
  }


  const interval = setInterval(() => dispatch({
    type: TIMER_TICK,
    payload: {
      now: new Date(),
    }
  }), 1000);

  dispatch({
    type: TIMER_START,
    payload: {
      interval,
      now: new Date(),
    }
  });

};