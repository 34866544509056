import axios from 'axios';
import { WEATHER_UPDATE, WEATHER_UPDATE_ERROR } from './types';

// https://fcc-weather-api.glitch.me/api/current?lat=52.430190&lon=4.642510

const WEATHER_SYMBOL = {
  "Unknown": "✨",
  "Cloudy": "☁️",
  "Fog": "🌫",
  "HeavyRain": "🌧",
  "HeavyShowers": "🌧",
  "HeavySnow": "❄️",
  "HeavySnowShowers": "❄️",
  "LightRain": "🌦",
  "LightShowers": "🌦",
  "LightSleet": "🌧",
  "LightSleetShowers": "🌧",
  "LightSnow": "🌨",
  "LightSnowShowers": "🌨",
  "PartlyCloudy": "⛅️",
  "Sunny": "☀️",
  "ThunderyHeavyRain": "🌩",
  "ThunderyShowers": "⛈",
  "ThunderySnowShowers": "⛈",
  "VeryCloudy": "☁️",
};

export const updateWeather = () => (dispatch, getState) => {
  const url = 'https://wttr.in/~51.9994572,4.362724538544?format=j1';


  const requestWeather = () => {
    axios.get(url)
      .then(({ data }) => {
        const temperature = Number(data.current_condition[0].temp_C);
        const feelsLike = Number(data.current_condition[0].FeelsLikeC);
        const description = data.current_condition[0].weatherDesc[0].value;
        const emoji = WEATHER_SYMBOL[description] || '';


        dispatch({
          type: WEATHER_UPDATE,
          payload: {
            temperature,
            feelsLike,
            emoji,
          }
        });
      })
      .catch(error => {
        dispatch({
          type: WEATHER_UPDATE_ERROR,
          payload: {}
        });
      });
  };
  const interval = setInterval(requestWeather, 900000);
  requestWeather();
  // setTimeout(requestWeather, 2000);

};
