import axios from 'axios';
import { AH_WORK_SHIFT_UPDATE, AH_WORK_SHIFT_UPDATE_ERROR } from './types';

export const requestAhWorkShift = () => (dispatch, getState) => {
  const url = 'https://wonder.kevinvandertoorn.com/ah-work/api/shift/upcoming_shift/';

  const requestAhWorkShift2 = () => {
    axios.get(url)
      .then(({ data }) => {
        dispatch({
          type: AH_WORK_SHIFT_UPDATE,
          payload: data
        });
      })
      .catch(error => {
        dispatch({
          type: AH_WORK_SHIFT_UPDATE_ERROR,
          payload: {}
        });
      });
  };

  requestAhWorkShift2();

};
