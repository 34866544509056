import { WEATHER_UPDATE } from '../actions/types';

const initialState = {
  temperature: null,
  feelsLike: null,
  emoji: '',
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case WEATHER_UPDATE:
      return {
        ...state,
        temperature: payload.temperature,
        feelsLike: payload.feelsLike,
        emoji: payload.emoji,
      };
    default:
      return state;
  }
};