// External dependencies
import { Component } from 'react';
import { connect } from 'react-redux';
import { toDate, utcToZonedTime } from 'date-fns-tz';

// CSS files
import './App.css';

// Local components
import Home from './Home';
import WorkShift from './WorkShift';

// Redux actions
import { startTimer } from '../actions/time';
import { updateWeather } from '../actions/weather';
import { requestAhWorkShift } from '../actions/ahwork';
import { getTheme } from '../actions/theme';
import { getTodoistTask } from '../actions/todoist';

class App extends Component {

  // Function to determine if the WorkShift component should be rendered
  shouldRenderWorkShift = () => {
    // Return false if work shift start_time is null or undefined
    if (!this.props.ahWorkShift.start_time) {
      return false;
    }

    // Define the Amsterdam timezone
    const amsterdamTimeZone = 'Europe/Amsterdam';

    // Get the current local time
    const currentTime = new Date();

    // Convert the work shift start time to Amsterdam timezone
    const targetTime = utcToZonedTime(toDate(this.props.ahWorkShift.start_time), amsterdamTimeZone);

    // Calculate the start of the event day (midnight)
    const startOfEventDay = new Date(targetTime);
    startOfEventDay.setHours(0, 0, 0, 0); // Set to midnight of the event day

    // Calculate three hours before the event day starts (21:00 the day before)
    const threeHoursBefore = new Date(startOfEventDay);
    threeHoursBefore.setHours(-3); // Set to 21:00 of the previous day

    // Return true if current time is between 21:00 the previous day and the event start time
    if (currentTime >= threeHoursBefore && currentTime < targetTime) {
      return true;
    }

    // Otherwise, return false
    return false;
  };

  // Lifecycle method that runs after the component is mounted
  componentDidMount() {
    // Start the timer when the component mounts
    this.props.startTimer();

    // Fetch and update the weather information
    this.props.updateWeather();

    // Set an interval to refresh weather information every 30 minutes
    setInterval(this.props.updateWeather, 30 * 60 * 1000);

    // Request the work shift details
    this.props.requestAhWorkShift();

    // Set an interval to refresh work shift details every 2 hours
    setInterval(this.props.requestAhWorkShift, 2 * 60 * 60 * 1000);

    // Fetch and apply the current theme
    this.props.getTheme();

    // Set an interval to refresh the theme every 30 minutes
    setInterval(this.props.getTheme, 30 * 60 * 1000);

    // Fetch the tasks from Todoist
    this.props.getTodoistTask();

    // Set an interval to refresh Todoist tasks every 5 minutes
    setInterval(this.props.getTodoistTask, 5 * 60 * 1000);
  }

  // Render method to conditionally display components
  render() {
    // If the work shift should be rendered, display the WorkShift component
    if (this.shouldRenderWorkShift()) {
      return <WorkShift />;
    }

    // Otherwise, display the Home component
    return <Home />;
  }
}

// Map Redux state to the component's props
const mapStateToProps = state => ({
  now: state.time.now, // Current time from Redux state
  ahWorkShift: state.ahwork, // Work shift information from Redux state
});

// Map dispatch actions to the component's props
const mapDispatchToProps = {
  startTimer, // Action to start the timer
  updateWeather, // Action to update weather data
  requestAhWorkShift, // Action to request work shift details
  getTheme, // Action to fetch the current theme
  getTodoistTask, // Action to fetch Todoist tasks
};

// Connect the component to Redux and export it
export default connect(mapStateToProps, mapDispatchToProps)(App);
