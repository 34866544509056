import { combineReducers } from 'redux';
import time from './time';
import weather from './weather';
import ahwork from './ahwork';
import theme from './theme';
import todoist from './todoist';

export default combineReducers({
  time,
  weather,
  ahwork,
  theme,
  todoist,
});