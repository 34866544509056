import { TIMER_START, TIMER_TICK } from "../actions/types";

const initialState = {
  interval: null,
  now: null,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {

    case TIMER_TICK:
      return {
        ...state,
        now: payload.now,
      };

    case TIMER_START:
      return {
        ...state,
        interval: payload.interval,
        now: payload.now,
      };

    default:
      return state;

  }
};