import { TODOIST_GET, TODOIST_GET_ERROR } from '../actions/types';

const initialState = {
  available: false,
};

export default (state = initialState, { type, payload }) => {
  console.log(type, payload, type === TODOIST_GET);
  switch (type) {
    case TODOIST_GET:
      return {
        ...payload,
      };
    case TODOIST_GET_ERROR:
      return {
        ...payload,
        available: false,
      };
    default:
      return state;
  }
};