import axios from 'axios';
import { THEME_GET, THEME_GET_ERROR } from './types';

export const getTheme = () => (dispatch, getState) => {
  const url = 'https://wonder.kevinvandertoorn.com/octo-dashboard/api/get-theme/';

  const getTheme2 = () => {
    axios.get(url)
      .then(({ data }) => {
        dispatch({
          type: THEME_GET,
          payload: data
        });
      })
      .catch(error => {
        dispatch({
          type: THEME_GET_ERROR,
          payload: {}
        });
      });
  };

  getTheme2();

};
