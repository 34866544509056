import axios from 'axios';
import { TODOIST_GET, TODOIST_GET_ERROR } from './types';

export const getTodoistTask = () => (dispatch, getState) => {
  const url = 'https://wonder.kevinvandertoorn.com/octo-dashboard/api/todoist-task/';

  const getTodoistTask2 = () => {
    axios.get(url)
      .then(({ data }) => {
        dispatch({
          type: TODOIST_GET,
          payload: data
        });
      })
      .catch(error => {
        dispatch({
          type: TODOIST_GET_ERROR,
          payload: {}
        });
      });
  };

  getTodoistTask2();

};
