// Time
export const TIMER_START = 'TIMER_START';
export const TIMER_TICK = 'TIMER_TICK';

// Weather
export const WEATHER_UPDATE = 'WEATHER_UPDATE';
export const WEATHER_UPDATE_ERROR = 'WEATHER_UPDATE_ERROR';

// AH Work
export const AH_WORK_SHIFT_UPDATE = 'AH_WORK_SHIFT_UPDATE';
export const AH_WORK_SHIFT_UPDATE_ERROR = 'AH_WORK_SHIFT_UPDATE_ERROR';

// Theme
export const THEME_GET = 'THEME_GET';
export const THEME_GET_ERROR = 'THEME_GET_ERROR';

// Todoist
export const TODOIST_GET = 'TODOIST_GET';
export const TODOIST_GET_ERROR = 'TODOIST_GET_ERROR';