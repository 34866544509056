import { AH_WORK_SHIFT_UPDATE, AH_WORK_SHIFT_UPDATE_ERROR } from '../actions/types';

const initialState = {
  duration: null,
  payout: null,
  start_time: null,
  end_time: null,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case AH_WORK_SHIFT_UPDATE:
      return {
        ...state,
        ...payload
      };
    default:
      return state;
  }
};