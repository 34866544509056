import { THEME_GET } from '../actions/types';

const initialState = {
  "id": null,
  "foreground": null,
  "background": null,
  "color": "#000000",
  "name": null,
  "in_rotation": null,
  "created_at": null,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case THEME_GET:
      return {
        ...state,
        ...payload
      };
    default:
      return state;
  }
};